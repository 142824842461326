import axios from "axios"
import BaseUrl from "../../utils/Baseurl"


// export const CreateReels=async(data)=>{
//     try {
//         const resoponse = await axios.post(`https://mosis-backend.cradle.services/Reel/uploadReel/66869d5d67f41b7560192410/soccor_Reel/soccor%20field/usa`,data)
//         return resoponse
//     } catch (error) {
//       console.error(error)
//       throw error;    
//     }
// } 


export const getAllreels=async(data)=>{
  
      try {
          const resoponse = await axios.get(`${BaseUrl}Reel/getall?page_no=1&page_size=22`,data)
          return resoponse.data
      } catch (error) {
        console.error(error) 
        throw error;   
      }
  }

  export const getreelbyuserid = async (data) => {
    const id=data;
    try {
      const response = await axios.get(`${BaseUrl}Reel/getByUserId/${id}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  export const deleteReels=async(id)=>{

    console.log(id,"myid for delete")
     
       try {
           const resoponse = await axios.delete(`${BaseUrl}Reel/remove/${id}`)
           return resoponse
       } catch (error) {
         console.error(error) 
         throw error;   
       }
   }

  
 