import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllreels, getreelbyuserid, deleteReels,  } from "../Api/ReelsApi"




const initialState = {
  reels: [],
  page: 1,
  loading: false,
  error: null,
};

// export const createUser = createAsyncThunk(
//   'reels/createUser',
//   async (data) => {
//     const { values, avatarFil } = data
//     try {
//       const response = await CreateUsers(values);
//       // const imageid = response.data.data._id
//       // if (response.data.isSuccess) {
//       //     createProfile({ imageid, avatarFil })
//       // }
//       return response.data;
//     } catch (error) {
//       console.error("Error creating user:", error);
//       throw error;
//     }
//   }
// );



export const fetchAllReels = createAsyncThunk(
  'reels/fetchAllUser',
  async (data) => {
    try {
      const response = await getAllreels(data);
      console.log(response, "reelslslsls")
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);

export const reelsgetbyid = createAsyncThunk(
  'reels/getbyidusers',
  async (data) => {
   
    try {
      const response = await getreelbyuserid(data);
      return response.data
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);
export const DeleteReels = createAsyncThunk(
  'reels/fetchdeleteUser',
  async (id) => {
    try {
      const response = await deleteReels(id);
      console.log(response, "Deleteusers")

      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);

// export const fetchsearchUser = createAsyncThunk(
//   'reels/fetchsearchUser',
//   async (searchValue) => {
//     console.log(searchValue,"check search")
//     try {
//       const response= await searchUser(searchValue);
//       return response.data ;
//     } catch (error) {
//       console.error("Error creating user:", error);
//       throw error;
//     }
//   }
// );








const reelsSlice = createSlice({
  name: 'ReelsSlice',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
    //   .addCase(createUser.fulfilled, (state, action) => {
    //     state.loading = false;
    //   })
    //   .addCase(createUser.pending, (state, action) => { 
    //     state.loading = true;
    //   })
    //   .addCase(createUser.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = "error";
    //   })
      // get all users----------

      .addCase(fetchAllReels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllReels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchAllReels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

            // usergetbyid-----
     .addCase(reelsgetbyid.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })

    // delete----------

    .addCase(DeleteReels.fulfilled, (state, action) => {
      state.loading = false;
    })

  



},
    });
export const { setPage } = reelsSlice.actions
export default reelsSlice.reducer;
