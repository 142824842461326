import axios from "axios"
import BaseUrl from "../../utils/Baseurl"

const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NmU4MTQ5YWQzOTNiNDVkN2VmNTI2OTAiLCJlbWFpbCI6ImFsaWNlLmpvaG5zb25AZXhhbXBsZS5jb20iLCJyb2xlIjoibWFzdGVyX2FkbWluIiwiaWF0IjoxNzQyMzcxNDYwfQ.Noadw3atj2yNjAmhg13PazKw8k41szTXli9RjQwmKt4"


export const CreateClub=async(data)=>{
    try {
        const resoponse = await axios.post(`https://mosis-backend.cradle.services/Club/create`,data)
        return resoponse
    } catch (error) {
      console.error(error)
      throw error;    
    }
}


export const getAllClub=async(data)=>{
  
     const option={
      headers: {
         
          'x-access-token': token
        }
     } 
      try {
          const resoponse = await axios.get(`${BaseUrl}Club/getall?page_no=1&page_size=11`,option)
          return resoponse.data
      } catch (error) {
        console.error(error) 
        throw error;   
      }
  }